<template>
  <section>
    <b-card>
      <div class="d-flex">
        <p class="my-auto mr-auto">
          <i :class="$route.meta.iconClass"></i>
          <span
            style="font-size: 22px; color: #7f7f7f"
            class="font-weight-light"
          >
            Fiche cadre :
          </span>
          <span style="font-size: 25px" class="font-weight-bold">
            {{ CADRE_ENTREPRISE.name }}</span
          >
        </p>

        <div class="row mb-4">
          <div class="col-12">
            <modal-actions
              @close="
                $router.go(
                  $store.getters['routerHistory/fromCreation'] ? -2 : -1
                )
              "
            />
          </div>
        </div>
      </div>
      <!-- {{ CADRE_ENTREPRISE }} -->
      <div class="d-flex gap">
        <div class="shdow col-md-8">
          <div
            class="d-flex flex-wrap justify-content-between align-items-center"
          >
            <p
              class="h4 text-secondary my-auto mr-auto"
              style="margin: 9.156px 0 !important"
            >
              Informations générales
            </p>
            <button
              :disabled="editModetype"
              v-if="(isAdmin || isSuper || isArchitect) && !editMode"
              class="btn p-0"
              style="
                font-size: 30px;
                color: #007bff !important;
                cursor: pointer;
              "
              @click="(editMode = !editMode), (editModedossiertype = true)"
            >
              <i
                class="mdi mdi-file-document-edit"
                style="font-size: 35px"
                :style="{
                  color: editModetype ? 'gray' : '#007bff',
                }"
              ></i>
            </button>
            <div
              v-else-if="isAdmin || isSuper || isArchitect"
              class="m-0 d-flex p-0"
            >
              <b-button
                style="min-width: 120px"
                type="submit"
                variant="secondary"
                pill
                @click="updateItem"
                :disabled="submitingForm"
                size="sm"
              >
                <b-spinner
                  v-if="submitingForm"
                  small
                  class="text-white"
                  label="Loading..."
                ></b-spinner>
                Confirmer
              </b-button>
              <b-button
                @click="
                  (editMode = !editMode),
                    (editModedossiertype = false),
                    (editModetype = false)
                "
                size="sm"
                pill
                style="background-color: #adb5bd; border-color: #adb5bd"
                >Annuler
              </b-button>
            </div>
          </div>
          <div class="col-12 p-0">
            <hr class="bg-secondary mb-3 mt-1" />
          </div>
          <!-- inputs -->
          <!-- First Col -->
          <div class="row" v-if="!editMode">
            <div class="col-md-6 col-12">
              <div class="my-4">
                <span>Libellé : </span>
                <span class="font-weight-bold">
                  {{ CADRE_ENTREPRISE.name }}</span
                >
              </div>

              <div class="my-4">
                <span>Type cadre : </span>
                <span class="font-weight-bold">{{
                  CADRE_ENTREPRISE.type
                }}</span>
              </div>
              <div class="my-4">
                <span>Statut : </span>
                <span class="font-weight-bold">{{
                  CADRE_ENTREPRISE.status
                }}</span>
              </div>
              <div class="my-4">
                <span>Redacteur : </span>
                <span class="font-weight-bold">{{
                  CADRE_ENTREPRISE.redacteur
                    ? CADRE_ENTREPRISE.redacteur.firstname +
                      " " +
                      CADRE_ENTREPRISE.redacteur.lastname
                    : "-"
                }}</span>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="my-4">
                <span>Responsable : </span>
                <span class="font-weight-bold">{{
                  CADRE_ENTREPRISE.responsable
                    ? CADRE_ENTREPRISE.responsable.firstname +
                      " " +
                      CADRE_ENTREPRISE.responsable.lastname
                    : "-"
                }}</span>
              </div>
              <div class="my-4">
                <span>Description : </span>
                <span class="font-weight-bold">{{
                  CADRE_ENTREPRISE.description
                    ? CADRE_ENTREPRISE.description
                    : "-"
                }}</span>
              </div>
              <div class="my-4">
                <span>Date de création : </span>
                <span class="font-weight-bold">{{
                  moment(
                    CADRE_ENTREPRISE.createdAt,
                    "DD/MM/YYYY HH:MM:ss"
                  ).format("DD/MM/YYYY")
                }}</span>
              </div>
            </div>
          </div>

          <!-- inputs -->
          <div class="row" v-else>
            <!-- First Col -->
            <div class="col-12 col-md-6 pr-1">
              <b-form-group
                label-cols-sm="4"
                label="Libellé :"
                label-class="font-weight-bold"
              >
                <b-form-input
                  type="text"
                  placeholder="Libellé"
                  v-model="newCadre.name"
                  :class="{
                    'is-invalid':
                      $v.newCadre.name.$error && $v.newCadre.name.$dirty,
                  }"
                ></b-form-input>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newCadre.name.$dirty"
                >
                  {{
                    !$v.newCadre.name.required
                      ? "Champ obligatoire"
                      : !$v.newCadre.name.minLength
                      ? `Le champ doit contenir au moins ${$v.newCadre.name.$params.minLength.min} caractères.`
                      : ""
                  }}
                </span>
              </b-form-group>
              <b-form-group
                label-cols-sm="4"
                label="Type cadre :"
                label-class="font-weight-bold"
              >
                <v-select
                  placeholder="Type"
                  type="text"
                  :options="['Pattern', 'Règle', 'Politique', 'Principe']"
                  style="padding-right: 0 !important"
                  v-model="newCadre.type"
                  :class="{
                    'is-invalid form-control p-0':
                      $v.newCadre.type.$error && $v.newCadre.type.$dirty,
                  }"
                >
                  <template #no-options> Liste vide </template>
                </v-select>
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newCadre.type.$dirty"
                >
                  {{ !$v.newCadre.type.required ? "Champ obligatoire" : "" }}
                </span>
              </b-form-group>
              <b-form-group
                label-cols-sm="4"
                label="Statut :"
                label-class="font-weight-bold"
              >
                <v-select
                  type="text"
                  :options="['Validé', 'Deployé']"
                  style="padding-right: 0 !important"
                  placeholder="Statut"
                  v-model="newCadre.status"
                  :class="{
                    'is-invalid form-control p-0':
                      $v.newCadre.status.$error && $v.newCadre.status.$dirty,
                  }"
                >
                  <template #no-options> Liste vide </template>
                </v-select>
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newCadre.status.$dirty"
                >
                  {{ !$v.newCadre.status.required ? "Champ obligatoire" : "" }}
                </span></b-form-group
              >
              <b-form-group
                label-cols-sm="4"
                label="Redacteur :"
                label-class="font-weight-bold"
              >
                <v-select
                  placeholder="Redacteur"
                  label="text"
                  :reduce="(user) => user.value"
                  :options="responsables"
                  v-model="newCadre.redacteur"
                  style="padding-right: 0 !important"
                  :class="{
                    'is-invalid form-control p-0':
                      $v.newCadre.redacteur.$error &&
                      $v.newCadre.redacteur.$dirty,
                  }"
                >
                  <template #no-options> Liste vide </template>
                </v-select>
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newCadre.redacteur.$dirty"
                >
                  {{
                    !$v.newCadre.redacteur.required ? "Champ obligatoire" : ""
                  }}
                </span>
              </b-form-group>
            </div>
            <div class="col-12 col-md-6 pl-1">
              <b-form-group
                label-cols-sm="4"
                label="Responsable :"
                label-class="font-weight-bold"
              >
                <v-select
                  placeholder="Responsable"
                  label="text"
                  :reduce="(user) => user.value"
                  :options="responsables"
                  v-model="newCadre.responsable"
                  style="padding-right: 0 !important"
                  :class="{
                    'is-invalid form-control p-0':
                      $v.newCadre.responsable.$error &&
                      $v.newCadre.responsable.$dirty,
                  }"
                >
                  <template #no-options> Liste vide </template>
                </v-select>
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newCadre.responsable.$dirty"
                >
                  {{
                    !$v.newCadre.responsable.required ? "Champ obligatoire" : ""
                  }}
                </span>
              </b-form-group>
              <b-form-group
                label-cols-sm="4"
                label="Description :"
                label-class="font-weight-bold"
              >
                <b-form-textarea
                  rows="5"
                  v-model="newCadre.description"
                  placeholder="Description"
                ></b-form-textarea>
              </b-form-group>

              <b-form-group
                label-cols-sm="4"
                label="Date création :"
                label-class="font-weight-bold"
              >
                <date-picker
                  disabled
                  format="DD/MM/YYYY"
                  v-model="newCadre.date"
                ></date-picker>
              </b-form-group>
            </div>
          </div>
          <!-- end inputs -->
        </div>
        <div class="shdow col-md-4">
          <div
            class="d-flex flex-wrap justify-content-between align-items-center"
          >
            <p
              class="h4 text-secondary my-auto mr-auto"
              style="margin: 9.156px 0 !important"
            >
              Documents
            </p>
            <button
              class="p-0 btn"
              v-if="(isAdmin || isSuper || isArchitect) && !editModedossier"
              @click="
                (editModedossier = !editModedossier), (editModetype = true)
              "
              :disabled="editModedossiertype"
              style="
                font-size: 30px;
                color: #007bff !important;
                cursor: pointer;
              "
            >
              <i
                class="mdi mdi-file-document-edit"
                style="font-size: 35px"
                :style="{
                  color: editModedossiertype ? 'gray' : '#007bff',
                }"
              ></i>
            </button>
            <div
              v-else-if="isAdmin || isSuper || isArchitect"
              class="m-0 d-flex p-0"
            >
              <b-button
                style="min-width: 120px"
                type="submit"
                variant="secondary"
                size="sm"
                pill
                @click="updatedossier"
                :disabled="submitingForm"
              >
                <b-spinner
                  v-if="submitingForm"
                  small
                  class="text-white"
                  label="Loading..."
                ></b-spinner>
                Confirmer
              </b-button>
              <b-button
                @click="
                  (editModedossier = !editModedossier),
                    (editModedossiertype = false),
                    (editModetype = false)
                "
                size="sm"
                pill
                style="background-color: #adb5bd; border-color: #adb5bd"
                >Annuler
              </b-button>
            </div>
          </div>
          <div class="col-12 p-0">
            <hr class="bg-secondary mb-3 mt-1" />
          </div>
          <!-- inputs -->
          <div class="row" v-if="!editModedossier">
            <div class="col-12">
              <div class="my-2">
                <p>
                  <span
                    v-if="
                      CADRE_ENTREPRISE.fileCadres
                        ? CADRE_ENTREPRISE.fileCadres
                        : false
                    "
                    style="color: black"
                    class="font-weight-bold"
                  >
                    <span
                      :key="index"
                      v-for="(item, index) in CADRE_ENTREPRISE.fileCadres"
                    >
                      <b-list-group class="mt-0">
                        <b-list-group-item>
                          <div class="col-12 p-0 d-flex">
                            <i
                              class="fa my-auto pr-1 fa-paperclip"
                              aria-hidden="true"
                            ></i>
                            <a target="_blank" :href="IMG_URL + item.fileUrl">
                              {{ item.filename }}</a
                            >
                            <i
                              @click="deleteFile(item)"
                              class="
                                fa
                                my-auto
                                fa-times
                                text-danger
                                ml-auto
                                justify-content-end
                              "
                              aria-hidden="true"
                              style="cursor: pointer"
                            ></i>
                          </div>
                        </b-list-group-item>
                      </b-list-group>
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div v-else class="col-12 p-0">
            <FilePicker
              @change="filePickerChanger"
              class="font-weight-normal"
            ></FilePicker>
            <p>
              <span
                v-if="
                  CADRE_ENTREPRISE.fileCadres
                    ? CADRE_ENTREPRISE.fileCadres
                    : false
                "
                style="color: black"
                class="font-weight-bold"
              >
                <span
                  :key="index"
                  v-for="(item, index) in CADRE_ENTREPRISE.fileCadres"
                >
                  <b-list-group class="mt-0">
                    <b-list-group-item>
                      <span class="col-12 p-0 d-flex text-truncate">
                        <i
                          class="fa my-auto pr-1 fa-paperclip"
                          aria-hidden="true"
                        ></i>
                        <a
                          target="_blank"
                          :href="IMG_URL + item.fileUrl"
                          class="text-truncate"
                        >
                          <span class="text-truncate">{{
                            item.filename
                          }}</span></a
                        >
                      </span>
                    </b-list-group-item>
                  </b-list-group>
                </span>
              </span>
            </p>
          </div>
        </div>
      </div>

      <hr style="margin-top: 30px" />
      <!-- End Tabs -->
      <div class="row my-5">
        <div class="col-12" style="margin-top: -20px">
          <b-tabs
            :value="$store.state.tabs.cadreTab"
            @changed="active = $store.state.tabs.cadreTab"
            @activate-tab="
              (newTabIndex) =>
                $store.dispatch('tabs/changeCadreTab', newTabIndex)
            "
            class="tab-solid tab-solid-primary mt-3"
          >
            <b-tab title="Capacités associées">
              <TechnicalCapacityTable
                :disabled="!(isAdmin || isSuper || isArchitect)"
                :items="capacite"
                :loading="loading"
              />
            </b-tab>
            <b-tab title="Système d’application">
              <SystemeTable
                :disabled="!(isAdmin || isSuper || isArchitect)"
                :items="sytemedapp"
                :loading="loading"
              />
            </b-tab>
            <b-tab title="Cadres associés">
              <sous-cadrstable
                :disabled="!(isAdmin || isSuper || isArchitect)"
                :items="CADRE_ENTREPRISE.souscadres"
                :loading="loading"
              />
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </b-card>
  </section>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";
//import Cadres from "./components/Cadres.vue";
//import Implementation from "./components/Implementations.vue";
import { mapGetters } from "vuex";
import { IMG_URL } from "@/helpers/services/api";
import { minLength, required } from "vuelidate/lib/validators";
import { datePickerFormat } from "@/helpers/DatePickerFormat.js";
import TechnicalCapacityTable from "../components/TechnicalCapacityTable";
import SousCadrstable from "../components/sousCadrstable";
import SystemeTable from "../components/SystemeTable";
import Swal from "sweetalert2";
import { concat } from "lodash";
import FilePicker from "../../../../components/custom/FilePicker";

export default {
  components: {
    FilePicker,
    SystemeTable,
    SousCadrstable,
    TechnicalCapacityTable,
    ModalActions,
  },
  data: () => ({
    file1: null,
    active: null,
    submitingForm: false,
    editMode: false,
    editModedossier: false,
    editModetype: false,
    editModedossiertype: false,
    capacite: null,
    IMG_URL,
    loading: true,
    sytemedapp: [],
    newCadre: {
      name: "",
      description: "",
      status: "",
      redacteur: "",
      typeRefcard: "",
      responsable: "",
      date: "",
    },
    cadre: {
      items: [
        {
          type: "Regles",
          code: 56,
          libelle: "Api station",
          couverture: 30,
          status: "Production",
          capacite: "Services d'echanges",
          implementaion: 4,
          livrable: 2,
          actions: ["edit", "delete", "show"],
        },
      ],
      fields: [
        { key: "type", label: "Type cadre", sortable: true },
        { key: "code", label: "Code", sortable: true },
        { key: "libelle", label: "Libellé", sortable: true },
        { key: "couverture", label: "Couverture projet", sortable: true },
        { key: "capacite", label: "Capacite technique", sortable: true },
        { key: "implementaion", label: "Implémentaion", sortable: true },
        { key: "status", label: "Statut", sortable: true },
        { key: "livrable", label: "Livrables", sortable: true },
        { key: "actions", label: "" },
      ],
    },
    impl: {
      items: [
        {
          type: "Composant Technique",
          libelle: "OVH",
          po: "O. GUISSE",
          capacite: "Cloud",
          patrimoine: 4,
          actions: ["edit", "delete", "show"],
        },
      ],
      fields: [
        { key: "type", label: "Type object", sortable: true },
        { key: "libelle", label: "Libellé object", sortable: true },
        { key: "po", label: "PO", sortable: true },
        { key: "capacite", label: "Capacité", sortable: true },
        { key: "patrimoine", label: "Implémentaion", sortable: true },
        { key: "actions", label: "" },
      ],
    },
  }),
  validations: {
    newCadre: {
      name: {
        required,
        minLength: minLength(5),
      },
      redacteur: {
        required,
      },
      status: {
        required,
      },
      type: {
        required,
      },
      responsable: {
        required,
      },
    },
  },
  created() {
    this.$store
      .dispatch("cadre/fetchComposant", this.$route.params.id)
      .then(() => {
        this.$store
          .dispatch("cadre/fetchCadre", this.$route.params.id)
          .then(() => {
            this.loading = false;
            this.$store.dispatch("loader/toggleLoading", false);
          });
      });

    this.$store.dispatch("users/fetchAllResponsables");
  },
  computed: {
    ...mapGetters("cadre", ["CADRE_ENTREPRISE"]),
    ...mapGetters("users", ["RESPONSABLES"]),
    ...mapGetters("cadre", ["COMPOSANT"]),
    ...mapGetters(["isAdmin", "isSuper", "isArchitect"]),
    responsables() {
      return this.RESPONSABLES.map((data) => ({
        ...data,
        text: data.firstname + " " + data.lastname,
        value: data.id,
      }));
    },
  },
  methods: {
    datePickerFormat,
    filePickerChanger(files) {
      this.file1 = files;
    },
    handleFileUpload(event) {
      var file = [];
      for (var i = 0; i < event.target.files.length; i++) {
        file.push(event.target.files[i]);
      }
      this.file1 = file;
    },
    updateItem() {
      this.$v.$touch();
      var data = {
        id: this.$route.params.id,
        name: this.newCadre.name,
        type: this.newCadre.type,
        redacteur: "api/users/" + this.newCadre.redacteur,
        description: this.newCadre.description,
        responsable: "api/users/" + this.newCadre.responsable,
        status: this.newCadre.status,
      };
      if (!this.$v.$invalid) {
        this.editModetype = false;
        this.editModedossiertype = false;
        this.$store.dispatch("cadre/updateCadre", data).then(() => {
          this.$store.dispatch("cadre/fetchCadre", this.$route.params.id);
          this.editMode = !this.editMode;

          Swal.fire({
            title: "Le cadre d'entreprise est mis à jour !",
            type: "success",
          });
        });
      }
    },
    updatedossier() {
      if (this.file1.length) {
        this.file1.map((data) => {
          data.append("cadre", this.CADRE_ENTREPRISE["@id"]);
          this.$store.dispatch("fileCadrs/createCadrsFile", data).then(() => {
            this.$store.dispatch("cadre/fetchCadre", this.$route.params.id);
            this.editModetype = false;
            this.editModedossiertype = false;
            this.editModedossier = !this.editModedossier;
            Swal.fire({
              title: "Le document est mis à jour !",
              type: "success",
            });
          });
        });
      }
    },
    deleteFile(id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          var data = parseInt(id.id);
          this.$store.dispatch("fileCadrs/deleteCadrsFile", data).then(() => {
            this.$store
              .dispatch("cadre/fetchCadre", this.$route.params.id)
              .then(() => {
                Swal.fire("Supprimé!", "", "success");
              });
          });
        }
      });
    },
  },
  watch: {
    CADRE_ENTREPRISE() {
      this.newCadre.name = this.CADRE_ENTREPRISE.name;
      this.newCadre.status = this.CADRE_ENTREPRISE.status;
      this.newCadre.description = this.CADRE_ENTREPRISE.description;
      this.newCadre.redacteur = this.CADRE_ENTREPRISE.redacteur.id;
      this.newCadre.responsable = this.CADRE_ENTREPRISE.responsable.id;
      this.newCadre.type = this.CADRE_ENTREPRISE.type;
      this.newCadre.date = datePickerFormat(this.CADRE_ENTREPRISE.createdAt);

      var composant = this.COMPOSANT
        ? this.COMPOSANT.composants.map((data) => ({
            ...data,
            type: "Composant Technique",
            systeme: data.name,
            po: data.archictecteTechnique,
            capacite: data.capacites
              ? data.capacites.length == 1
                ? data.capacites[0]
                : data.capacites
                    .filter((data) => data.parent)
                    .map((data) => data.parent)[0]
              : "-",
          }))
        : [];
      var cap = this.CADRE_ENTREPRISE.capacites.map((data) => ({
        ...data,
        type: "Technique",
        name: data.name,
        niveau: data.niveau ? data.niveau : "-",
      }));
      this.capacite = cap;
      var x = this.CADRE_ENTREPRISE.applications.map((data) => ({
        ...data,
        type: "Application",
        systeme: data.name,
        capacite: data.capaciteSis
          ? data.capaciteSis.length == 1
            ? data.capaciteSis[0]
            : data.capaciteSis
                .filter((data) => data.parent)
                .map((data) => data.parent)[0]
          : "-",
      }));
      this.sytemedapp = concat(x, composant);
    },
  },
};
</script>
<style>
.gap {
  gap: 10px;
}
</style>
