var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('b-card',[_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"my-auto mr-auto"},[_c('i',{class:_vm.$route.meta.iconClass}),_c('span',{staticClass:"font-weight-light",staticStyle:{"font-size":"22px","color":"#7f7f7f"}},[_vm._v(" Fiche cadre : ")]),_c('span',{staticClass:"font-weight-bold",staticStyle:{"font-size":"25px"}},[_vm._v(" "+_vm._s(_vm.CADRE_ENTREPRISE.name))])]),_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-12"},[_c('modal-actions',{on:{"close":function($event){return _vm.$router.go(
                _vm.$store.getters['routerHistory/fromCreation'] ? -2 : -1
              )}}})],1)])]),_c('div',{staticClass:"d-flex gap"},[_c('div',{staticClass:"shdow col-md-8"},[_c('div',{staticClass:"d-flex flex-wrap justify-content-between align-items-center"},[_c('p',{staticClass:"h4 text-secondary my-auto mr-auto",staticStyle:{"margin":"9.156px 0 !important"}},[_vm._v(" Informations générales ")]),((_vm.isAdmin || _vm.isSuper || _vm.isArchitect) && !_vm.editMode)?_c('button',{staticClass:"btn p-0",staticStyle:{"font-size":"30px","color":"#007bff !important","cursor":"pointer"},attrs:{"disabled":_vm.editModetype},on:{"click":function($event){(_vm.editMode = !_vm.editMode), (_vm.editModedossiertype = true)}}},[_c('i',{staticClass:"mdi mdi-file-document-edit",staticStyle:{"font-size":"35px"},style:({
                color: _vm.editModetype ? 'gray' : '#007bff',
              })})]):(_vm.isAdmin || _vm.isSuper || _vm.isArchitect)?_c('div',{staticClass:"m-0 d-flex p-0"},[_c('b-button',{staticStyle:{"min-width":"120px"},attrs:{"type":"submit","variant":"secondary","pill":"","disabled":_vm.submitingForm,"size":"sm"},on:{"click":_vm.updateItem}},[(_vm.submitingForm)?_c('b-spinner',{staticClass:"text-white",attrs:{"small":"","label":"Loading..."}}):_vm._e(),_vm._v(" Confirmer ")],1),_c('b-button',{staticStyle:{"background-color":"#adb5bd","border-color":"#adb5bd"},attrs:{"size":"sm","pill":""},on:{"click":function($event){(_vm.editMode = !_vm.editMode),
                  (_vm.editModedossiertype = false),
                  (_vm.editModetype = false)}}},[_vm._v("Annuler ")])],1):_vm._e()]),_c('div',{staticClass:"col-12 p-0"},[_c('hr',{staticClass:"bg-secondary mb-3 mt-1"})]),(!_vm.editMode)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 col-12"},[_c('div',{staticClass:"my-4"},[_c('span',[_vm._v("Libellé : ")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.CADRE_ENTREPRISE.name))])]),_c('div',{staticClass:"my-4"},[_c('span',[_vm._v("Type cadre : ")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.CADRE_ENTREPRISE.type))])]),_c('div',{staticClass:"my-4"},[_c('span',[_vm._v("Statut : ")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.CADRE_ENTREPRISE.status))])]),_c('div',{staticClass:"my-4"},[_c('span',[_vm._v("Redacteur : ")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.CADRE_ENTREPRISE.redacteur ? _vm.CADRE_ENTREPRISE.redacteur.firstname + " " + _vm.CADRE_ENTREPRISE.redacteur.lastname : "-"))])])]),_c('div',{staticClass:"col-md-6 col-12"},[_c('div',{staticClass:"my-4"},[_c('span',[_vm._v("Responsable : ")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.CADRE_ENTREPRISE.responsable ? _vm.CADRE_ENTREPRISE.responsable.firstname + " " + _vm.CADRE_ENTREPRISE.responsable.lastname : "-"))])]),_c('div',{staticClass:"my-4"},[_c('span',[_vm._v("Description : ")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.CADRE_ENTREPRISE.description ? _vm.CADRE_ENTREPRISE.description : "-"))])]),_c('div',{staticClass:"my-4"},[_c('span',[_vm._v("Date de création : ")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.moment( _vm.CADRE_ENTREPRISE.createdAt, "DD/MM/YYYY HH:MM:ss" ).format("DD/MM/YYYY")))])])])]):_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6 pr-1"},[_c('b-form-group',{attrs:{"label-cols-sm":"4","label":"Libellé :","label-class":"font-weight-bold"}},[_c('b-form-input',{class:{
                  'is-invalid':
                    _vm.$v.newCadre.name.$error && _vm.$v.newCadre.name.$dirty,
                },attrs:{"type":"text","placeholder":"Libellé"},model:{value:(_vm.newCadre.name),callback:function ($$v) {_vm.$set(_vm.newCadre, "name", $$v)},expression:"newCadre.name"}}),(_vm.$v.newCadre.name.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.newCadre.name.required ? "Champ obligatoire" : !_vm.$v.newCadre.name.minLength ? `Le champ doit contenir au moins ${_vm.$v.newCadre.name.$params.minLength.min} caractères.` : "")+" ")]):_vm._e()],1),_c('b-form-group',{attrs:{"label-cols-sm":"4","label":"Type cadre :","label-class":"font-weight-bold"}},[_c('v-select',{class:{
                  'is-invalid form-control p-0':
                    _vm.$v.newCadre.type.$error && _vm.$v.newCadre.type.$dirty,
                },staticStyle:{"padding-right":"0 !important"},attrs:{"placeholder":"Type","type":"text","options":['Pattern', 'Règle', 'Politique', 'Principe']},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" Liste vide ")]},proxy:true}]),model:{value:(_vm.newCadre.type),callback:function ($$v) {_vm.$set(_vm.newCadre, "type", $$v)},expression:"newCadre.type"}}),(_vm.$v.newCadre.type.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.newCadre.type.required ? "Champ obligatoire" : "")+" ")]):_vm._e()],1),_c('b-form-group',{attrs:{"label-cols-sm":"4","label":"Statut :","label-class":"font-weight-bold"}},[_c('v-select',{class:{
                  'is-invalid form-control p-0':
                    _vm.$v.newCadre.status.$error && _vm.$v.newCadre.status.$dirty,
                },staticStyle:{"padding-right":"0 !important"},attrs:{"type":"text","options":['Validé', 'Deployé'],"placeholder":"Statut"},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" Liste vide ")]},proxy:true}]),model:{value:(_vm.newCadre.status),callback:function ($$v) {_vm.$set(_vm.newCadre, "status", $$v)},expression:"newCadre.status"}}),(_vm.$v.newCadre.status.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.newCadre.status.required ? "Champ obligatoire" : "")+" ")]):_vm._e()],1),_c('b-form-group',{attrs:{"label-cols-sm":"4","label":"Redacteur :","label-class":"font-weight-bold"}},[_c('v-select',{class:{
                  'is-invalid form-control p-0':
                    _vm.$v.newCadre.redacteur.$error &&
                    _vm.$v.newCadre.redacteur.$dirty,
                },staticStyle:{"padding-right":"0 !important"},attrs:{"placeholder":"Redacteur","label":"text","reduce":(user) => user.value,"options":_vm.responsables},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" Liste vide ")]},proxy:true}]),model:{value:(_vm.newCadre.redacteur),callback:function ($$v) {_vm.$set(_vm.newCadre, "redacteur", $$v)},expression:"newCadre.redacteur"}}),(_vm.$v.newCadre.redacteur.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.newCadre.redacteur.required ? "Champ obligatoire" : "")+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-12 col-md-6 pl-1"},[_c('b-form-group',{attrs:{"label-cols-sm":"4","label":"Responsable :","label-class":"font-weight-bold"}},[_c('v-select',{class:{
                  'is-invalid form-control p-0':
                    _vm.$v.newCadre.responsable.$error &&
                    _vm.$v.newCadre.responsable.$dirty,
                },staticStyle:{"padding-right":"0 !important"},attrs:{"placeholder":"Responsable","label":"text","reduce":(user) => user.value,"options":_vm.responsables},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" Liste vide ")]},proxy:true}]),model:{value:(_vm.newCadre.responsable),callback:function ($$v) {_vm.$set(_vm.newCadre, "responsable", $$v)},expression:"newCadre.responsable"}}),(_vm.$v.newCadre.responsable.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.newCadre.responsable.required ? "Champ obligatoire" : "")+" ")]):_vm._e()],1),_c('b-form-group',{attrs:{"label-cols-sm":"4","label":"Description :","label-class":"font-weight-bold"}},[_c('b-form-textarea',{attrs:{"rows":"5","placeholder":"Description"},model:{value:(_vm.newCadre.description),callback:function ($$v) {_vm.$set(_vm.newCadre, "description", $$v)},expression:"newCadre.description"}})],1),_c('b-form-group',{attrs:{"label-cols-sm":"4","label":"Date création :","label-class":"font-weight-bold"}},[_c('date-picker',{attrs:{"disabled":"","format":"DD/MM/YYYY"},model:{value:(_vm.newCadre.date),callback:function ($$v) {_vm.$set(_vm.newCadre, "date", $$v)},expression:"newCadre.date"}})],1)],1)])]),_c('div',{staticClass:"shdow col-md-4"},[_c('div',{staticClass:"d-flex flex-wrap justify-content-between align-items-center"},[_c('p',{staticClass:"h4 text-secondary my-auto mr-auto",staticStyle:{"margin":"9.156px 0 !important"}},[_vm._v(" Documents ")]),((_vm.isAdmin || _vm.isSuper || _vm.isArchitect) && !_vm.editModedossier)?_c('button',{staticClass:"p-0 btn",staticStyle:{"font-size":"30px","color":"#007bff !important","cursor":"pointer"},attrs:{"disabled":_vm.editModedossiertype},on:{"click":function($event){(_vm.editModedossier = !_vm.editModedossier), (_vm.editModetype = true)}}},[_c('i',{staticClass:"mdi mdi-file-document-edit",staticStyle:{"font-size":"35px"},style:({
                color: _vm.editModedossiertype ? 'gray' : '#007bff',
              })})]):(_vm.isAdmin || _vm.isSuper || _vm.isArchitect)?_c('div',{staticClass:"m-0 d-flex p-0"},[_c('b-button',{staticStyle:{"min-width":"120px"},attrs:{"type":"submit","variant":"secondary","size":"sm","pill":"","disabled":_vm.submitingForm},on:{"click":_vm.updatedossier}},[(_vm.submitingForm)?_c('b-spinner',{staticClass:"text-white",attrs:{"small":"","label":"Loading..."}}):_vm._e(),_vm._v(" Confirmer ")],1),_c('b-button',{staticStyle:{"background-color":"#adb5bd","border-color":"#adb5bd"},attrs:{"size":"sm","pill":""},on:{"click":function($event){(_vm.editModedossier = !_vm.editModedossier),
                  (_vm.editModedossiertype = false),
                  (_vm.editModetype = false)}}},[_vm._v("Annuler ")])],1):_vm._e()]),_c('div',{staticClass:"col-12 p-0"},[_c('hr',{staticClass:"bg-secondary mb-3 mt-1"})]),(!_vm.editModedossier)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"my-2"},[_c('p',[(
                    _vm.CADRE_ENTREPRISE.fileCadres
                      ? _vm.CADRE_ENTREPRISE.fileCadres
                      : false
                  )?_c('span',{staticClass:"font-weight-bold",staticStyle:{"color":"black"}},_vm._l((_vm.CADRE_ENTREPRISE.fileCadres),function(item,index){return _c('span',{key:index},[_c('b-list-group',{staticClass:"mt-0"},[_c('b-list-group-item',[_c('div',{staticClass:"col-12 p-0 d-flex"},[_c('i',{staticClass:"fa my-auto pr-1 fa-paperclip",attrs:{"aria-hidden":"true"}}),_c('a',{attrs:{"target":"_blank","href":_vm.IMG_URL + item.fileUrl}},[_vm._v(" "+_vm._s(item.filename))]),_c('i',{staticClass:"fa my-auto fa-times text-danger ml-auto justify-content-end",staticStyle:{"cursor":"pointer"},attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.deleteFile(item)}}})])])],1)],1)}),0):_vm._e()])])])]):_c('div',{staticClass:"col-12 p-0"},[_c('FilePicker',{staticClass:"font-weight-normal",on:{"change":_vm.filePickerChanger}}),_c('p',[(
                _vm.CADRE_ENTREPRISE.fileCadres
                  ? _vm.CADRE_ENTREPRISE.fileCadres
                  : false
              )?_c('span',{staticClass:"font-weight-bold",staticStyle:{"color":"black"}},_vm._l((_vm.CADRE_ENTREPRISE.fileCadres),function(item,index){return _c('span',{key:index},[_c('b-list-group',{staticClass:"mt-0"},[_c('b-list-group-item',[_c('span',{staticClass:"col-12 p-0 d-flex text-truncate"},[_c('i',{staticClass:"fa my-auto pr-1 fa-paperclip",attrs:{"aria-hidden":"true"}}),_c('a',{staticClass:"text-truncate",attrs:{"target":"_blank","href":_vm.IMG_URL + item.fileUrl}},[_c('span',{staticClass:"text-truncate"},[_vm._v(_vm._s(item.filename))])])])])],1)],1)}),0):_vm._e()])],1)])]),_c('hr',{staticStyle:{"margin-top":"30px"}}),_c('div',{staticClass:"row my-5"},[_c('div',{staticClass:"col-12",staticStyle:{"margin-top":"-20px"}},[_c('b-tabs',{staticClass:"tab-solid tab-solid-primary mt-3",attrs:{"value":_vm.$store.state.tabs.cadreTab},on:{"changed":function($event){_vm.active = _vm.$store.state.tabs.cadreTab},"activate-tab":(newTabIndex) =>
              _vm.$store.dispatch('tabs/changeCadreTab', newTabIndex)}},[_c('b-tab',{attrs:{"title":"Capacités associées"}},[_c('TechnicalCapacityTable',{attrs:{"disabled":!(_vm.isAdmin || _vm.isSuper || _vm.isArchitect),"items":_vm.capacite,"loading":_vm.loading}})],1),_c('b-tab',{attrs:{"title":"Système d’application"}},[_c('SystemeTable',{attrs:{"disabled":!(_vm.isAdmin || _vm.isSuper || _vm.isArchitect),"items":_vm.sytemedapp,"loading":_vm.loading}})],1),_c('b-tab',{attrs:{"title":"Cadres associés"}},[_c('sous-cadrstable',{attrs:{"disabled":!(_vm.isAdmin || _vm.isSuper || _vm.isArchitect),"items":_vm.CADRE_ENTREPRISE.souscadres,"loading":_vm.loading}})],1)],1)],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }